<template>
  <div>
    <div
      ref="head-wrap"
      :class="{
       'head-widget-wrap_desk': !isMobile,
       'head-widget-wrap_mobile': isMobile
      }"
      :style="getStyle"
    >
      <div ref="wrapper" class="wrapper" :style="wrapStyle" :class="{ 'is-fixed': model.fixed }">
        <client-only>
          <notice-widget :device="device" :editing="editing" :model="site.noticeSetting" @close="initFixed" />
          <pwa-widget :device="device" :editing="editing" :model="site.pwaSetting" />
        </client-only>
        <bg-style
          ref="head"
          :bg="model.background"
          :mobile-bg="model.mobileBackground"
          :style="{
          'color': model.headerFontColorShow
            ? model.headerFontColor
            : 'unset',
          }"
          class="head-widget"
        >
          <!-- pc端 -->
          <div v-if="!isMobile" class="relative flex items-center justify-between header_desk_wrap">
            <!-- left logo & navs -->
            <div class="flex items-center">
              <!-- logo  -->
              <div
                v-if="!!logoImage.backgroundImageShow"
                :class="{ 'min-w-[140px]': editing }"
                class="header_logo_wrap flex items-center relative flex-shrink-0 mr-[76px] justify-center"
              >
                <img
                  v-if="logoImage.backgroundImage"
                  :src="logoImage.backgroundImage"
                  alt
                  class="h-[46px] max-w-[150px]"
                  @load="generateNavItems"
                />
                <CoverBlock
                  v-if="editing"
                  :title="$t('edit.setImage')"
                  class="logo_cover_block"
                  @click="handleOpenLogoUpload"
                />
              </div>
            </div>
            <div ref="navWrap" :class="['header_nav_wrap', {'header_nav_wrap_edit' : editing}]">
              <div
                ref="nav"
                class="flex items-center overflow-x-auto nav_wrap_auto flex-nowrap whitespace-nowrap nav-inner"
              >
                <div
                  v-for="(n, i) in navsData"
                  :key="n.key"
                  :class="['flex items-center cursor-pointer nav_item', {'no_logo_first_nav' : i === 0 && !logoImage.backgroundImageShow}]"
                  @click="handleSkipNav(n)"
                >{{ n.label }}
                </div>
                <transition name="el-fade-in">
                  <Popover v-if="overList.length" :visible-arrow="false" placement="bottom-start" popper-class="header-popper">
                    <bg-style :bg="model.customBackImg" :style="varStyle" class="header-popper__menu">
                      <div v-for="(item, menuIndex) in overList" :key="menuIndex"
                           :style="getTextColor(model.headerFontColor, model.headerFontColorShow)"
                           class="item relative"
                           @click="handleSkipNav(item)">
                        {{ item.label }}
                      </div>
                    </bg-style>
                    <div slot="reference" class="more-item flex justify-center items-center cursor-pointer">
                      <div v-for="item in [1, 2, 3]" :key="item" class="dot"/>
                    </div>
                  </Popover>
                </transition>
              </div>
            </div>
            <div class="flex items-center h-full ml-[76px] flex-shrink-0">
              <!-- language -->
              <div v-if="isShowLang" class="header_language flex-shrink-0">
                <lang-select
                  ref="langRef"
                  :device="device"
                  :editing="editing"
                  position="bottom"
                  source="header"
                  @open="infoShow = false"
                />
              </div>
              <div v-if="hasShopCart" class="mr-10 cursor-pointer" @click="handleToShopCart">
                <el-badge :hidden="shopCartNumber <= 0" :value="shopCartNumber">
                  <im-icon class="text-[32px]" icon="icon-gouwuche1"/>
                </el-badge>
              </div>
              <!-- user info -->
              <div class="flex items-center h-full header_avatar flex-shrink-0">
                <div
                  v-if="!isShowLoginMenu"
                  :style="loginBtnBgImg"
                  class="h-[54px] flex items-center cursor-pointer"
                >
                  <div class="flex items-center justify-center default_user_info">
                    <im-icon class="text-[18px] user-icon" icon="icon-a-Login"/>
                  </div>
                  <span class="leading-6 text-[15px]">{{ $t('siteBuild.header.please') }}</span>
                </div>
                <div v-if="isShowLoginMenu" class="flex items-center h-full">
                  <div
                    v-if="!siteLoginUserInfo"
                    :style="loginBtnBgImg"
                    class="h-[54px] flex items-center cursor-pointer"
                    @click.stop="handleOpenBind"
                  >
                    <div class="flex items-center justify-center default_user_info">
                      <im-icon class="text-[18px] user-icon" icon="icon-a-Login"/>
                    </div>
                    <span slot="reference" class="leading-6 text-[15px]">{{ usernameInfo }}</span>
                  </div>
                  <Popover
                    v-else
                    v-model="infoShow"
                    placement="bottom"
                    popper-class="header_avatar_popover"
                    trigger="manual"
                  >
                    <div
                      slot="reference"
                      :style="loginBtnBgImg"
                      class="h-[54px] flex items-center cursor-pointer"
                      @click.stop="handleShowDeskLogin"
                    >
                      <div class="flex items-center justify-center default_user_info">
                        <im-icon class="text-[24px] user-icon" icon="icon-a-Login"/>
                      </div>
                      <span slot="reference" class="leading-6 text-18">{{ usernameInfo }}</span>
                    </div>
                    <div>
                      <div
                        class="flex items-center h-10 px-3 rounded-sm cursor-pointer header_avatar_item text-14"
                        @click="handleOpenLogin"
                      >
                        {{ siteLoginUserInfo ? $t('siteBuild.header.accountDetail') : $t('siteBuild.header.login1') }}
                      </div>
                      <div
                        v-if="!siteLoginUserInfo"
                        class="flex items-center h-10 px-3 rounded-sm cursor-pointer header_avatar_item text-14"
                        @click="handleOpenBind"
                      >{{ $t('siteBuild.header.bindUserAccount') }}
                      </div>
                      <div
                        v-if="siteLoginUserInfo"
                        class="flex items-center h-10 px-3 rounded-sm cursor-pointer header_avatar_item text-14"
                        @click="handleLoginOut"
                      >{{ $t('siteBuild.header.exit') }}
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
          <!-- 移动端 -->
          <div v-if="isMobile" class="relative flex items-center header_mobile_wrap">
            <!-- navs -->
            <div
              v-if="!showMobileNavs"
              class="flex items-center justify-center"
              @click.stop="handleShowMobileNav"
            >
              <im-icon class="text-[24px] menu-icon cursor-pointer" icon="icon-menu"/>
            </div>
            <div
              v-if="showMobileNavs"
              class="flex items-center justify-center"
              @click.stop="handleCloseMobileNav"
            >
              <im-icon class="text-[24px] menu-icon cursor-pointer" icon="icon-close"/>
            </div>
            <el-drawer :show-close="false" :size="286" :visible.sync="showMobileNavs" :with-header="false" append-to-body direction="ltr">
              <bg-style :bg="model.customBackImg" :style="varStyle" class="header_mobile_nav_wrap">
                <div class="mobile_navs relative">
                  <div
                    v-for="n in navsData"
                    :key="n.key"
                    :style="getTextColor(model.headerFontColor, model.headerFontColorShow)"
                    class="cursor-pointer mobile_navs_item"
                    @click="handleSkipNav(n)"
                  >{{ n.label }}
                  </div>
                  <lang-select
                    v-if="isShowLang"
                    :device="device"
                    :editing="editing"
                    :style="getTextColor(model.headerFontColor, model.headerFontColorShow)"
                    class="w-full"
                    position="bottom"
                    source="header"
                    @close="handleCloseMobileNav"
                  />
                </div>
              </bg-style>
            </el-drawer>
            <div class="flex items-center justify-center flex-1 px-5">
              <div
                v-if="!!logoImage.backgroundImageShow"
                :class="['header_logo_wrap flex items-center justify-center relative', {'min-w-[130px] ' : editing}]"
              >
                <img
                  v-if="logoImage.backgroundImage"
                  :src="logoImage.backgroundImage"
                  alt
                  class="h-full rounded-sm"
                />
                <CoverBlock
                  v-if="editing"
                  :title="$t('edit.setImage')"
                  class="logo_cover_block"
                  @click="handleOpenLogoUpload"
                />
              </div>
            </div>
            <!-- user info -->
            <div
              v-if="loginShow"
              class="w-[32px] h-[32px] flex items-center justify-center"
              @click.stop="handleCloseMobileLogin"
            >
              <im-icon class="text-[20px] user-icon" icon="icon-a-Login"/>
            </div>
            <div v-if="!loginShow" class="header_avatar flex flex-shrink-0 items-center">
              <div v-if="hasShopCart" class="mr-5 pt-1" @click="handleToShopCart">
                <el-badge :hidden="shopCartNumber <= 0" :value="shopCartNumber">
                  <im-icon class="text-[26px]" icon="icon-gouwuche1"/>
                </el-badge>
              </div>
              <div
                class="flex items-center justify-center cursor-pointer default_user_info"
                @click.stop="handleShowMobileLogin"
              >
                <im-icon class="text-[20px] user-icon" icon="icon-a-Login"/>
              </div>
            </div>
          </div>
        </bg-style>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {Popover, Drawer} from 'element-ui'
import {LangPositionEnum} from '../../model/index'
import LangSelect from '../../components/langSelect'
import {useLoginState, LoginStateEnum} from '../loginSetting/components/useLogin'
import {PageEnum} from '~/enums/pageEnum'
import Bus from '~/site/model/bus'
import PwaWidget from "~/site/widgets/pwaWidget/index.vue";
import NoticeWidget from '~/site/widgets/noticeWidget/index.vue'
import {DeviceEnum, DeviceWidthEnum} from "~/enums/deviceEnum";
import ImIcon from "~/components/common/ImIcon.vue";
import {getTextColor, navTo, sleep} from "~/utils";

const isBuildSite = process.env.IS_BUILD_SITE

export default {
  name: "HeaderWidget",
  components: {
    ImIcon,
    PwaWidget,
    NoticeWidget,
    LangSelect,
    Popover,
    [Drawer.name]: Drawer
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    site: {
      type: Object,
      default() {
        return {}
      },
    },
    editing: {
      type: Boolean,
      default: false
    },
    device: {
      type: String,
      default: 'desktop'
    },
  },
  data() {
    const {setLoginState, handleBackLogin} = useLoginState()

    return {
      id: '', // 用户角色ID
      navList: [],
      overList: [],
      resisterSeconds: 0,
      wrapperWidth: 0,
      wrapperHeight: 0,
      logining: false, // 登录中
      checked: false, // 是否检查用户角色ID
      checking: false, // 检查中
      showCar: false, // 是否显示购物车
      infoShow: false, //  是否现示用户详情菜单
      infoModel: false, //  是否现示用户详情
      isHover: false,
      showMobileNavs: false,
      loginLoading: false,
      alertMessage: {
        type: '',
        message: '',
      },
      LangPositionEnum,
      LoginStateEnum,
      setLoginState,
      handleBackLogin,
    }
  },
  computed: {
    ...mapGetters(['shopCartNumber']),
    ...mapState({
      siteLoginUserInfo: state => state.user.siteLoginUserInfo,
      userInfo: state => state.user.siteUserInfo || {},
      loginShow: state => state.user.loginShow || false, // 显示登录弹窗
      projectInfo: state => state.project.info,
    }),
    isMobile() {
      return this.device === DeviceEnum.MOBILE
    },
    onlyUidLogin() {
      return this.site.loginSetting.onlyUidLogin
    },
    siteId() {
      return this.projectInfo?.id
    },
    isShowLang() {
      return this.site && this.site.langSetting && this.site.langSetting.langPosition === LangPositionEnum.TOP
    },
    logoImage() {
      const customBackImg = this.model.logoBackImg;
      return customBackImg.value ? customBackImg : {}
    },
    navsData() {
      return this.navList.length ? this.navList : this.model.navs.filter(item => {
        return item.label
      })
    },
    usernameInfo() {
      if (this.siteLoginUserInfo) {
        return this.siteLoginUserInfo.login_type === '1'
          ? this.siteLoginUserInfo.player?.name || this.siteLoginUserInfo?.nickname
          : this.siteLoginUserInfo.email || '';
      }
      return this.$t('siteBuild.header.login')
    },
    // 非建站器模式显示登录相关选项
    isShowLoginMenu() {
      if (isBuildSite) {
        return isBuildSite
      }
      return this.$route.path.indexOf(PageEnum.SITE_PREVIEW) === 0
    },
    // 登录按钮背景图片
    loginBtnBgImg() {
      const customBtnBgImg = this.model.loginBtnBgImg;
      const bgImage = customBtnBgImg.backgroundImage;
      const bgImageShow = customBtnBgImg.backgroundImageShow;
      if (customBtnBgImg.value && bgImage && bgImageShow) {
        return {
          backgroundImage: bgImage ? `url(${bgImage})` : 'none',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundColor: '#fff',
          padding: bgImage ? '0px 12px' : '0'
        }
      }
      return {}
    },
    // 建站器&移动端&登录弹窗
    isEditMobileLogin() {
      return this.editing && this.isMobile && this.loginShow
    },
    hasShopCart() {
      return this.site.globalSetting.shopCartEnabled
    },
    wrapStyle() {
      return {
        width: this.wrapperWidth
      }
    },
    varStyle() {
      return {
        '--header-text-color': getTextColor(this.model.headerFontColor, this.model.headerFontColorShow).color
      }
    },
    getStyle() {
      return {
        height: this.wrapperHeight ? this.wrapperHeight + 'px' : null
      }
    }
  },
  watch: {
    'model.fixed'() {
      this.initFixed();
    },
    'device'() {
      this.initFixed()
      this.generateNavItems()
    },
    isEditMobileLogin(val) {
      this.initFixed();
      document.getElementById('site-body').style.setProperty('overflow', val ? 'hidden' : 'auto')
    },
    'site.pwaSetting.enabled'() {
      this.initFixed()
    },
    'model.navs': {
      deep: true,
      handler() {
        this.generateNavItems()
      }
    }
  },
  mounted() {
    this.setDefaultDeviceWidth()
    this.checkShowLogin()
    Bus.$on('closeLoginModal', () => {
      if (this.isMobile) this.handleFixedBg(false)
    })
    Bus.$on('closeMobileLoginModal', () => {
      this.handleCloseMobileLogin()
    })
    // 登录弹出层
    document.body.addEventListener('click', this.handleCloseDeskLogin)
    this.checkLogoNav()
    this.initFixed()
  },
  destroyed() {
    Bus.$off('closeLoginModal')
    document.body.removeEventListener('click', this.handleCloseDeskLogin)
  },
  methods: {
    getTextColor,
    checkLogoNav() {
      if (!this.logoImage.backgroundImage || !this.logoImage.backgroundImageShow) this.generateNavItems()
    },
    async generateNavItems() {
      if (this.isMobile) return
      this.navList = []
      this.overList = []
      await this.$nextTick()
      await sleep(500)
      const navData = this.model.navs.filter(item => item.label)
      const $wrap = this.$refs.navWrap
      const $nav = this.$refs.nav
      if (!$wrap || !$nav) return
      const warpWidth = $wrap.offsetWidth
      const $items = $wrap.querySelectorAll('.nav_item')
      const isBeyond = $wrap.offsetWidth < $nav.offsetWidth
      let navWidth = 0
      let spliceIndex
      if (isBeyond) {
        for (let i = 0; i < $items.length; i++) {
          const current = $items[i]
          navWidth += current.offsetWidth
          if (navWidth > warpWidth) {
            spliceIndex = i - 1
            break
          }
        }
        this.navList = navData.slice(0, spliceIndex)
        this.overList = navData.slice(spliceIndex, navData.length)
      }
    },
    checkShowLogin() {
      const isLogin = !!this.$utils.getSiteToken(this)
      const hasLogin = isLogin && this.userInfo?.uid
      const autoOpen = this.site.loginSetting.autoOpen
      if (this.editing) return
      if (autoOpen && !hasLogin) {
        this.handleOpenBind()
      }
    },
    // handleToCoupon() {
    //   this.SitePage.toCoupon()
    // },
    handleToShopCart() {
      this.SitePage.toShopCart()
    },
    setDefaultDeviceWidth() {
      this.$nextTick(() => {
        const width = document.getElementById('site-body')?.clientWidth
        this.$store.commit('editor/SET_PC_DEVICE_WIDTH', width)
        this.$store.commit('editor/SET_DEVICE_WIDTH', width)
      })
    },
    // scrollToLastNav() {
    //   this.$nextTick(() => {
    //     const el = document.querySelector('.nav_item:last-child')
    //     el && el.scrollIntoView({
    //       behavior: "smooth"
    //     })
    //   })
    // },
    initFixed() {
      this.$nextTick(() => {
        const pcWidth = this.editing ? (this.$store.state.editor.pcDeviceWidth - 2) + 'px' : '100%'
        const mobileWidth = this.editing ? (DeviceWidthEnum.MOBILE - 10) + 'px' : '100%'
        const isFixed = this.model.fixed
        if (isFixed) {
          this.wrapperHeight = this.$refs.wrapper.offsetHeight
          this.wrapperWidth = this.isMobile ? mobileWidth : pcWidth
        } else {
          this.wrapperWidth = null
          this.wrapperHeight = null
        }
      })
    },
    handleOpenLogoUpload() {
      this.SiteMenu.showImageWithKey('head-logo-image');
    },
    handleShowDeskLogin() {
      this.infoShow = !this.infoShow
      if (this.$refs.langRef) this.$refs.langRef.clickOut()
    },
    handleCloseDeskLogin() {
      this.infoShow = false
    },
    handleShowMobileLogin() {
      if (this.editing) return
      this.handleFixedBg(false)
      this.showMobileNavs = false
      this.infoShow = true
      this.handleOpenBind()
    },
    handleCloseMobileLogin() {
      if (this.editing) return
      this.handleFixedBg(false)
      this.infoShow = false
      this.$store.commit('user/SET_LOGINSHOW', false)
      this.$store.commit('user/SET_SITE_BIND_USER', false)
      this.$store.commit('site/SET_SHOP_GOOD', null)
    },
    handleOpenLogin() {
      this.infoShow = false
      this.resisterSeconds = 0
      this.setLoginState(LoginStateEnum.LOGIN)
      const isLogin = !!this.$utils.getSiteToken(this)
      if (isLogin && this.userInfo?.uid) {
        this.id = this.userInfo.uid
        this.checked = true
      } else {
        this.checked = false
      }
      this.$store.commit('user/SET_LOGINSHOW', true)
      if (this.isMobile) this.handleFixedBg(true)
    },
    handleOpenBind() {
      this.infoShow = false
      this.resisterSeconds = 0
      const isLogin = !!this.$utils.getSiteToken(this)
      if (isLogin && this.userInfo?.uid) {
        this.id = this.userInfo.uid
        this.checked = true
      } else {
        this.checked = false
      }
      this.$store.commit('user/SET_LOGINSHOW', true)
      this.$store.commit('user/SET_SITE_BIND_USER', true)
      if (this.isMobile) this.handleFixedBg(true)
    },
    handleLoginOut() {
      this.checked = false
      this.id = ''
      this.infoShow = false
      this.$utils.siteUserLoginOut(this)
      this.SitePage.toHome()
      Bus.$emit('reloadGood', 'login-out')
      Bus.$emit('reloadActivity')
    },
    handleSkipNav(info) {
      if (this.editing) return
      this.handleCloseMobileNav()
      navTo(this, info)
    },
    onNoticeClose() {
      this.wrapperHeight = this.wrapperHeight - 48
    },
    handleShowMobileNav() {
      if (!this.editing) {
        this.infoShow = false
        this.$store.commit('user/SET_LOGINSHOW', false)
        this.$store.commit('user/SET_SITE_BIND_USER', false)
        this.showMobileNavs = true
        this.handleFixedBg(true)
      }
    },
    handleCloseMobileNav() {
      this.handleFixedBg(false)
      this.showMobileNavs = false
    },
    handleFixedBg(flag) {
      if (flag) {
        this.initFixed(true)
      } else {
        this.initFixed(this.model.fixed)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
<style lang="less">
.header-popper {
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: 0;

  .item {
    font-family: PingFang SC;
    line-height: 44px;
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid var(--header-text-color);
    padding-left: 15px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:last-of-type {
      border-bottom: 0;
    }

    &:hover {
      padding-left: 30px;
    }
  }
}

.header_avatar_popover {
  width: 210px;
  padding: 4px;
  border: 1px solid #ebeef1;
  border-radius: 2px;

  .header_avatar_item {
    font-family: PingFangSC-Regular;
    color: #18171c;
    margin-bottom: 2px;

    &:hover {
      background-color: rgba(235, 238, 241, 0.6);
    }
  }
}
</style>
