<template>
  <widget-content
    :bg="model.background"
    :mobile-bg="model.mobileBackground"
    :class="isMobile && 'is-mobile'"
    class="widget-footer"
  >
    <div
      :class="
        isMobile
          ? 'footer_content_mobile relative z-10'
          : 'footer_content_desk relative z-10'
      "
      :style="{
        color: model.footerFontColorShow ? model.footerFontColor : 'unset',
      }"
    >
      <div
        v-if="showLangSelect"
        class="footer_lang_select border-b border-b-fillColor-3"
      >
        <LangSelectComponent
          :device="device"
          :editing="editing"
          source="footer"
        ></LangSelectComponent>
      </div>
      <div v-for="(item, index) in model.children" :key="index">
        <div v-if="item.name === 'FooterTextWidgetModel'" class="my-4">
          <rich-text v-model="item.text" :disabled="!editing" :editing="editing" />
        </div>
        <div v-if="item.name === 'FooterMediaWidgetModel'" class="pb-6 mt-2 border-b border-b-fillColor-3">
          <div class="footer-media">{{ item.title }}</div>
          <div class="flex items-center mt-2">
            <bg-style
              v-for="(tag, mediaIndex) in item.navs"
              :key="mediaIndex"
              :bg="tag.logo"
              class="item w-[28px] h-[28px] mr-8 cursor-pointer"
              @click.native="handleClickToNav(tag)"
            />
          </div>
        </div>
        <div v-if="item.name === 'FooterQuickLinkWidgetModel'" class="flex justify-between items-center flex-wrap my-4">
          <div class="quick-link__column">
            <div v-for="(tag, navIndex) in item.navs" :key="navIndex" class="item mr-[60px] cursor-pointer text-14" @click="handleClickToNav(tag)">{{ tag.label }}
            </div>
          </div>
          <div v-if="item.logoVisible" class="quick-link__logo">
            <bg-style :bg="model.logoBackground" class="w-[120px] h-[40px]"/>
          </div>
        </div>
      </div>
    </div>
  </widget-content>
</template>

<script>
import {mapState} from 'vuex'
import {FooterWidgetModel} from '../../model/index'
import LangSelectComponent from '../../components/langSelect'
import WidgetContent from '~/site/components/widgetContent'
import {DefaultImage} from '~/enums/defaultImageEnum'
import {navTo} from '~/utils'
import {DeviceEnum} from "~/enums/deviceEnum";
import RichText from "~/components/richText/index.vue";

export default {
  name: 'FooterWidget',
  components: {
    RichText,
    LangSelectComponent,
    WidgetContent
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      default() {
        return {}
      },
    },
    model: {
      type: Object,
      default() {
        return new FooterWidgetModel()
      },
    },
    device: {
      type: String,
      default: 'desktop',
    },
  },
  data() {
    return {
      DefaultImage,
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    ...mapState({
      realDevice: (state) => state.device.device,
    }),
    // 是否移动端
    isMobile() {
      const device = this.editing ? this.device : this.realDevice
      return device === 'mobile'
    },
    // 是否含有图片
    isHaveImage() {
      return this.model.children.some(
        (item) => item.name === 'FooterImageWidgetModel'
      )
    },
    isHaveMedia() {
      return this.model.children.some(
        (item) => item.name === 'FooterMediaWidgetModel'
      )
    },
    // 文本集合
    textImageData() {
      return (
        this.model.children.filter(
          (item) =>
            item.name === 'FooterTextWidgetModel' ||
            item.name === 'FooterImageWidgetModel'
        ) || []
      )
    },
    // 链接集合
    linkData() {
      return (
        this.model.children.filter(
          (item) => item.name === 'FooterQuickLinkWidgetModel'
        ) || []
      )
    },
    mediaData() {
      return this.model.children.filter(
        (item) => item.name === 'FooterMediaWidgetModel'
      )
    },
    showLangSelect() {
      return (
        this.site &&
        this.site.langSetting &&
        this.site.langSetting.langPosition === 'bottom'
      )
    },
  },
  methods: {
    footerTextChange(text, block) {
      this.$set(block, 'text', text)
      this.$set(block, 'isTextEdit', true)
    },
    handleShowLogo() {
      this.SiteMenu.closeShowImage('logo-image')
    },
    handleClickToNav(item) {
      if (this.editing) return
      navTo(this, item)
    },
  },
}
</script>

<style lang="less" scoped>
.widget-footer {
  color: var(--text-color);

  &.is-mobile {
    margin-top: -28px;
  }
}

.footer_content_desk {
  padding: 36px 0;

  .footer_logo {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;

    .logo_cover_block {
      position: absolute !important;
      height: 40px !important;
    }
  }

  .quick-link__column {
    display: flex;
    align-items: center;
    column-gap: 60px;
  }

  .footer_lang_select {
    padding-bottom: 24px;

    .lang-select-component {
      padding: 7px;
      border: 1px solid white;
      border-radius: 2px;
    }
  }

  .footer_text {
    width: 660px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .footer_text_image {
    display: flex;
    flex-wrap: wrap;

    .item_text_image {
      width: 20%;
      display: flex;
      justify-items: center;

      .footer-image {
        width: 100%;
        height: 60px;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  .split_line_block {
    margin: 36px 0;
  }

  .split_line_none {
    margin: 18px 0;
  }

  .footer_link {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.footer_content_mobile {
  padding: 24px 16px;
  margin-top: 28px;

  .footer_lang_select {
    padding-bottom: 24px;

    .lang-select-component {
      padding: 4px;
      border: 1px solid white;
      border-radius: 2px;
    }
  }

  .quick-link__column {
    .item {
      margin-bottom: 12px;

      //&:last-of-type {
      //  margin-bottom: 26px;
      //}
    }
  }

  .quick-link__logo {
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid @fill-color-3;
  }

  .footer_text {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .footer_text_image {
    .item_text_image {
      width: 100%;

      .footer-image {
        width: 100%;
        height: 60px;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .footer_link {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.footer_logo_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_split_line {
  width: 100%;
  height: 1px;
  position: relative;
  z-index: 99;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
