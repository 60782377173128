<template>
  <bg-style class="store-widget-v2__row-column-list-item" :bg="model.goodsBackground" @click.native="handleClick">
    <im-image v-if="isHasCorner" :src="good.corner" class="corner-logo" />
    <bg-style class="thumb relative" :bg="{...good.thumbBg, backgroundSize: model.iconFillType}" />
    <div class="name relative">{{ good.names.name }}</div>
    <div v-if="isShowCountdown" class="pre-btn relative">
      <i class="el-icon-time mr-2" />
      {{ realPriceText }}
    </div>
    <div v-else class="price-btn relative" @click.stop="handleSubmit">
      {{ realPriceText }}
    </div>
  </bg-style>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import goodMixins from "~/site/widgets/storeWidget/oneGood/goodMixins";

export default {
  name: 'StoreWidgetV2RowColumnListItem',
  components: {
  },
  mixins: [goodMixins],
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    handleClick() {
      if (this.editing || this.isShowCountdown) return
      this.$emit('click', this.good)
    },
    handleSubmit() {
      this.$emit('submit', cloneDeep(this.good))
    }
  }
}
</script>

<style lang="less">
.store-widget-v2__row-column-list-item {
  flex-shrink: 0;
  width: 140px;
  height: fit-content;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: var(--goods-border-radius);
  .color-bg {
    border-radius: var(--goods-border-radius);
  }
  .corner-logo {
    position: absolute;
    left: -3px;
    top: -3px;
    width: 62px;
    height: 62px;
    z-index: 10;
  }
  .thumb {
    width: 100%;
    height: 140px;
    border-radius: var(--goods-border-radius) var(--goods-border-radius) 0 0;
    overflow: hidden;
  }
  .name {
    font-size: 12px;
    color: var(--main-text-color);
    font-weight: bold;
    padding: 0 8px;
    margin-top: 8px;
  }
  .original-price {
    font-size: 10px;
    text-decoration: line-through;
    color: var(--second-text-color);
  }
  .pre-btn {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-align: center;
    margin: 8px;
    border-radius: var(--goods-border-radius);
    font-size: 12px;
    line-height: 24px;
  }
  .price-btn {
    line-height: 24px;
    background-color: var(--button-bg-color);
    font-size: 12px;
    border-radius: var(--goods-border-radius);
    padding: 0 5px;
    color: var(--button-text-color);
    margin: 8px;
    text-align: center;
  }
}
</style>
