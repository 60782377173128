<template>
  <div class="footer-media-edit">
    <menu-title :close="close">媒体配置</menu-title>
    <menu-item class="!pt-0" label="标题">
      <base-input v-model="target.title" />
    </menu-item>
    <div v-for="(item, index) in target.navs" :key="index" class="media-item bg-fillColor-2 p-2 mt-4">
      <div class="flex justify-between text-12">
        <span>媒体{{ index + 1 }}</span>
        <i class="el-icon-delete cursor-pointer hover:text-danger" @click="handleRemove(index)" />
      </div>
      <menu-item class="pt-3" label="图片">
        <menu-background-image :target="item.logo" />
      </menu-item>
      <menu-item class="pt-2" label="链接">
        <base-input v-model="item.url" />
      </menu-item>
    </div>
    <menu-button class="mt-4" icon="add" @click.native.stop="handleAdd">添加媒体项</menu-button>
    <remove-btn class="mt-4" :click-fun="deleteBlock" />
  </div>
</template>

<script>
import FooterMixin from "~/site/widgets/footerWidget/mixinEdit";
import MenuButton from "~/components/menu/menuButton.vue";
import RemoveBtn from "~/components/common/RemoveBtn.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";

export default {
  name: 'FooterMediaEdit',
  components: {MenuBackgroundImage, RemoveBtn, MenuButton},
  mixins: [FooterMixin],
  props: {
    target: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleAdd() {
      this.target.__addItem()
    },
    handleRemove(index) {
      this.target.navs.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="less">

</style>
