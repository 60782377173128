<template>
  <bg-style class="store-widget-v2" :class="{ 'is-mobile': $store.getters.isMobile }" :bg="model.background" :style="getStyle">
    <div class="store-widget-v2__classify relative">
      <div class="content-area">
        <rich-text v-if="model.titleVisible" v-model="model.title"  :disabled="!editing" :editing="editing" :exclude="['font-size']" :class="$store.getters.isDesktop ? 'text-[40px]' : 'text-[22px]'" />
        <div class="group-list mt-4 flex items-center gap-4">
          <div v-for="(item, index) in groupList" :key="index" class="item" :class="{ 'active': currentIndex === index }" @click="handleChangeClassify(item, index)">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <pc-list v-if="$store.getters.isDesktop" :list="groupGoodsMap[currentClassify] || templateList" :site="site" :editing="editing" :model="model" @show-detail="onShowDetail" @submit="onSubmit" />
    <one-column-list v-if="$store.getters.isMobile && model.mobileLayoutType === StoreWidgetV2FlexLayoutEnum.ONE_COLUMN" :list="groupGoodsMap[currentClassify] || templateList" :site="site" :editing="editing" :model="model" @show-detail="onShowDetail" @submit="onSubmit" />
    <two-column-list v-if="$store.getters.isMobile && model.mobileLayoutType === StoreWidgetV2FlexLayoutEnum.TWO_COLUMN" :list="groupGoodsMap[currentClassify] || templateList" :site="site" :editing="editing" :model="model" @show-detail="onShowDetail" @submit="onSubmit" />
    <row-column-list  v-if="$store.getters.isMobile && model.mobileLayoutType === StoreWidgetV2FlexLayoutEnum.ROW_COLUMN" :list="groupGoodsMap[currentClassify] || templateList" :site="site" :editing="editing" :model="model" @show-detail="onShowDetail" @submit="onSubmit" />
    <detail-modal ref="$detail" :good="currentGoods" :immediate="false" :editing="editing" @submit="onSubmit" />
    <desk-pay-modal v-if="deskPay.visible" :desk-pay="deskPay" :lang-id-data="$store.state.locale.langIdData" :store-lang="storeLang" @close="closePayModal" />
  </bg-style>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import {Message} from "element-ui";
import PcList from './components/pcList/index.vue'
import TwoColumnList from './components/twoColumnList/index.vue'
import OneColumnList from './components/oneColumnList/index.vue'
import RowColumnList from './components/rowColumnList/index.vue'
import DetailModal from './components/detailModal.vue'
import RichText from "~/components/richText/index.vue";
import tools, {
  checkIgnoreMessage, checkUser,
  formatGoods,
  generateOrderParams,
  generatePayUrl,
  getTextColor,
  sleep
} from "~/utils";
import DeskPayModal from "~/components/pay/deskPayModal.vue";
import Bus from "~/site/model/bus";
import {StoreWidgetV2FlexLayoutEnum} from "~/enums/layoutEnum";

export default {
  name: 'StoreWidgetV2',
  components: {
    DeskPayModal,
    RichText,
    PcList,
    DetailModal,
    TwoColumnList,
    OneColumnList,
    RowColumnList
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false,
    },
    device: {
      type: String,
      default: 'desktop',
    }
  },
  data() {
    return {
      currentIndex: 0,
      currentGoods: {},
      storeLang: null,
      currentClassify: null,
      groupGoodsMap: {},
      orderLoading: false,
      deskPay: {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
    }
  },
  computed: {
    templateList() {
      return this.editing ? this.model.__mockData : []
    },
    StoreWidgetV2FlexLayoutEnum() {
      return StoreWidgetV2FlexLayoutEnum
    },
    awaitGoods() {
      return this.$store.state.site.awaitGoods
    },
    groupList() {
      return this.model.classifyList.map(item => {
        return this.$store.state.goods.groupList.find(group => group.value === item.value)
      }).filter(item => item)
    },
    getStyle() {
      return {
        '--main-text-color': getTextColor(this.model.mainTextColor).color,
        '--second-text-color': getTextColor(this.model.secondaryTextColor).color,
        '--button-bg-color': getTextColor(this.model.buttonBackground).color,
        '--button-text-color': getTextColor(this.model.buttonTextColor).color,
        '--goods-bg-color': getTextColor(this.model.goodsBackground).color,
        '--goods-border-radius': this.model.goodsRadius + 'px'
      }
    }
  },
  watch: {
    'model.classifyList': {
      deep: true,
      immediate: true,
      async handler(newVal) {
        await this.getGoodsByGroupIds()
        this.currentClassify = newVal?.[0]?.value
        this.currentIndex = 0
      }
    }
  },
  created() {
    this.init()
  },
  mounted() {
    Bus.$on('reloadGood', (type) => {
      const isLogin = type === 'login'
      if (isLogin) {
        this.checkBuy()
      }
    })
  },
  methods: {
    async init() {
      this.storeLang = tools.getStoreLang(this)
      this.currentClassify = this.model.classifyList?.[0]?.value
      await this.$store.dispatch('goods/getShopCart', this)
      await this.$store.dispatch('goods/getGroupList', this)
      await this.getGoodsByGroupIds()
    },
    async getGoodsByGroupIds() {
      for (let i = 0; i < this.groupList.length; i++) {
        const item = this.groupList[i]
        const params = {
          pageSize: 200,
          pageNo: 1,
          group_id: item.value,
          project_id: this.$store.state.project.projectId
        }
        if (!this.groupGoodsMap[item.value]) {
          const [err, res] = await this.$utils.to(this.$api.good.getVirtualItems(params, { editing: this.editing }))
          if (!err && res.group?.id) {
            this.$set(this.groupGoodsMap, res.group.id, formatGoods(res?.data || [], this))
          }
        }
      }
    },
    checkBuy() {
      const isAim = this.awaitGoods && (this.awaitGoods.modelId === this.model.id)
      if (isAim) this.createOrder(this.awaitGoods.data)
    },
    async getGoodsByCurrentClassify() {
      const params = {
        pageSize: 200,
        pageNo: 1,
        group_id: this.currentClassify,
        project_id: this.$store.state.project.projectId
      }
      const [err, res] = await this.$utils.to(this.$api.good.getVirtualItems(params, { editing: this.editing }))
      if (!err && res.group?.id) {
        this.$set(this.groupGoodsMap, res.group.id, formatGoods(res?.data || [], this))
      }
    },
    closePayModal() {
      this.$store.commit('site/SET_SHOP_GOOD', null)
      this.getGoodsByCurrentClassify()
      Bus.$emit('reloadActivity', 'payment')
      this.deskPay = {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
    },
    handleChangeClassify(item, index) {
      this.currentClassify = item.value
      this.currentIndex = index
    },
    async onSubmit(goods) {
      if (this.orderLoading) return
      this.currentGoods = goods
      await checkUser(this, () => {
        this.$refs.$detail.close()
        this.$store.commit('site/SET_AWAIT_GOODS', { modelId: this.model.id, data: cloneDeep(this.currentGoods) })
      })
      await this.createOrder()
    },
    async createOrder(goods) {
      this.orderLoading = true
      const [err, res] = await this.$utils.to(generateOrderParams(this, goods || this.currentGoods))
      this.orderLoading = false
      this.$refs.$detail.close()
      await sleep(500)
      if (!err) {
        this.$store.commit('site/SET_AWAIT_GOODS', null)
        generatePayUrl(res, this)
      } else {
        !checkIgnoreMessage(err) && Message.error(err)
      }
    },
    onShowDetail(data) {
      if (this.editing) return
      this.currentGoods = cloneDeep(data)
      this.$refs.$detail.init()
    }
  }
}
</script>

<style lang="less">
.store-widget-v2 {
  padding: 0 0 40px 0;
  &__classify {
    padding: 60px 0 20px 0;
    background-color: var(--goods-bg-color);
    .content-area {
      max-width: @maxWidth;
      margin: 0 auto;
      .group-list {
        font-size: 16px;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .item {
          padding: 5px 12px;
          color: var(--second-text-color);
          cursor: pointer;
          flex-shrink: 0;
          &.active {
            background-color: var(--button-bg-color);
            color: var(--button-text-color);
          }
        }
      }
    }
  }
  &.is-mobile {
    .store-widget-v2__classify {
      padding: 40px 14px 20px 14px;
      .group-list {
        font-size: 12px;
      }
    }
  }
}
</style>
